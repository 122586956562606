import React from "react"
import WindUI from "../../images/wind-ui.png"
import { Helmet } from "react-helmet"

const Seo = props => {
  const title = props?.title || ""
  const ogtitle = props?.ogtitle || ""
  const description = props?.description || ""
  const ogdescription = props?.ogdescription || ""
  const image = props?.ogimage || WindUI
  const url = props?.url || ""
  const keywords = props?.keywords?.keywords || ""
  const robots = props?.robots || ""
  const revisitAfter = props?.revisitAfter || ""
  const author = props.author || "Wind UI"

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        title={title}
        meta={[
          { property: "og:title", content: ogtitle },
          { name: "description", content: description },
          { property: "og:description", content: ogdescription },
          { name: "author", content: author },
          {
            property: "og:image",
            content: image && `https://wind-ui.com${image}`,
          },
          {
            property: "og:image:url",
            name: "image",
            content: image && `https://wind-ui.com${image}`,
          },
          { name: "keywords", content: keywords },
          { name: "robots", content: robots },
          { name: "revisit-after", content: revisitAfter },
          { property: "og:type", content: "website" },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:creator", content: "WindUI" },
          { name: "twitter:title", content: title },
          { name: "twitter:description", content: description },
          {
            name: "twitter:url",
            content: typeof window !== "undefined" ? location.href : "",
          },
          { name: "twitter:image", content: image && `https:${image}` },
        ]}
      >
        <link rel="canonical" href={`https://wind-ui.com/${url}`} />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "WindUI",
            "url": "https://wind-ui.com/",
            "headline": "${title}",
            "description": "${description}"
          }`}
        </script>
      </Helmet>
    </>
  )
}

export default Seo
